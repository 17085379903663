import React, { Component } from 'react';
import {
  BrowserRouter as Router, Switch, Route, Redirect,
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import check from 'check-types';
import blacklist from 'blacklist';
import firebase from 'firebase';
import moment from 'moment';
import sha1 from 'sha1';
import { translationManager } from '../../lib/TranslationManager';

import AuthListener from './listeners/AuthListener';
import RidesListener from './listeners/RidesListener';
import RoutesAndLocationsListener from './listeners/RoutesAndLocationsListener';
import UserDataListener from './listeners/UserDataListener';
import ExpandedRideListener from './listeners/ExpandedRideListener';

import WelcomePage from './pages/WelcomePage';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import SettingsPage from './pages/SettingsPage';
import SignUpPage from './pages/SignUpPage';
import SignUpOkPage from './pages/SignUpOkPage';
import OrdersPage from './pages/OrdersPage';
import TicketDetailsPage from './pages/TicketDetailsPage';
import TicketStopsPage from './pages/TicketStopsPage';
import TicketBoardingPassPage from './pages/TicketBoardingPassPage';
import BookingFormPage from './pages/BookingFormPage';
import OutTripsListPage from './pages/OutTripsListPage';
import ReturnTripsListPage from './pages/ReturnTripsListPage';
import OutTripDetailsPage from './pages/OutTripDetailsPage';
import OutTripSeatSelectionPage from './pages/OutTripSeatSelectionPage';
import OutTripStopsPage from './pages/OutTripStopsPage';
import ReturnTripDetailsPage from './pages/ReturnTripDetailsPage';
import ReturnTripSeatSelectionPage from './pages/ReturnTripSeatSelectionPage';
import ReturnTripStopsPage from './pages/ReturnTripStopsPage';
import PassengerDetailsPage from './pages/PassengerDetailsPage';
import CheckoutPage from './pages/CheckoutPage';
import CreditCardPaymentPage from './pages/CreditCardPaymentPage';
import BookingConfirmedPage from './pages/BookingConfirmedPage';
import AddPassengerPopup from './pages/AddPassengerPopup';
import EditPassengersPage from './pages/EditPassengersPage';
import DepartureDatePopup from './pages/DepartureDatePopup';
import ReturnDatePopup from './pages/ReturnDatePopup';
import LanguageSettingsPage from './pages/LanguageSettingsPage';

export default class Busea extends Component {
  static defaultUserState() {
    return {
      loggedIn: false,
      uid: null,
      lang: null,
      email: null,
      emailVerified: false,
      otherEmails: {},
      ordersById: {},
      lastLocationsSearched: {},
      lastRoutesSearched: {},
      savedPassengers: {},
      cards: {},
      passengerId: null,
    };
  }

  static defaultSessionState() {
    return {
      isSigningIn: false,
      hasSeenWelcomeScreen: false,
      selectedDeparture: null,
      selectedArrival: null,
      numberOfPassengers: 1,
      outDate: moment().format('YYYY-MM-DD'),
      returnDate: null,
      passengerDetails: null,
      outboundTrip: null,
      returnTrip: null,
    };
  }

  static getSavedState() {
    // Create initial state
    const state = {
      user: Busea.defaultUserState(),

      session: Busea.defaultSessionState(),

      cache: {
        routes: {},
        locations: {},
        searches: {},
      },
    };

    // Get locally saved state if any
    let parsedState = {
      user: {},
      session: {},
      cache: {},
    };
    try {
      const savedState = localStorage.getItem('busea.store');

      if (check.nonEmptyString(savedState)) {
        parsedState = JSON.parse(savedState);
      }
    } catch (err) {
      // Do nothing
    }

    // If a previous state was saved, apply it
    // TODO: Better checks for security
    if (
      check.nonEmptyObject(parsedState)
      && check.nonEmptyObject(parsedState.user)
      && check.nonEmptyObject(parsedState.session)
      && check.nonEmptyObject(parsedState.cache)
    ) {
      state.user = Object.assign(state.user, parsedState.user);
      state.session = Object.assign(state.session, parsedState.session);
      state.cache = Object.assign(state.cache, parsedState.cache);
    }

    // Make sure we don't restore dates from the past
    const { outDate, returnDate } = state.session;
    let outDateMoment = outDate ? moment(outDate) : moment();
    let returnDateMoment = returnDate ? moment(returnDate) : null;

    if (outDateMoment && outDateMoment.valueOf() < Date.now()) {
      outDateMoment = moment();
    }
    if (returnDateMoment && returnDateMoment.valueOf() < Date.now()) {
      returnDateMoment = moment();
    }
    if (outDateMoment && returnDateMoment && outDateMoment.valueOf() > returnDateMoment.valueOf()) {
      returnDateMoment = outDateMoment;
    }
    state.session = Object.assign(state.session, {
      outDate: outDateMoment.format('YYYY-MM-DD'),
      returnDate: returnDateMoment ? returnDateMoment.format('YYYY-MM-DD') : null,
    });

    // Clear some stuff from the session
    /*
    state.session = Object.assign(state.session, {
      outboundTrip: null,
      returnTrip: null,
    });
    */

    // Return
    return state;
  }

  static saveState(state) {
    localStorage.setItem('busea.store', JSON.stringify(state));
  }

  constructor(...args) {
    super(...args);
    this.state = Busea.getSavedState();

    // Bindings
    this.onAuthStateChange = this.onAuthStateChange.bind(this);
    this.onRoutesUpdate = this.onRoutesUpdate.bind(this);
    this.onLocationsUpdate = this.onLocationsUpdate.bind(this);
    this.onRidesUpdate = this.onRidesUpdate.bind(this);
    this.onExpandedRideDataUpdate = this.onExpandedRideDataUpdate.bind(this);
    this.onUserDataUpdate = this.onUserDataUpdate.bind(this);
    this.getRidesData = this.getRidesData.bind(this);
    this.setSession = this.setSession.bind(this);
    this.setUserState = this.setUserState.bind(this);
    this.setCacheState = this.setCacheState.bind(this);

    // Init translation manager
    translationManager.init('busea', firebase, {}, '/translations');
  }

  async onLogin(uid, email) {
    // Get remote user state
    const { user: local } = this.state;
    const remote = await firebase
      .database()
      .ref(`/users/${uid}`)
      .once('value');

    // Merge it with our own
    const merged = this.getMergedUserState(
      local,
      Object.assign(remote.val() || {}, { uid, loggedIn: true, email }),
    );

    // Set user state
    this.setUserState(merged);
  }

  async onLogout() {
    await this.setUserState(Busea.defaultUserState());
    await this.setSession(Busea.defaultSessionState());

    console.log('👋 Successfully logged out!');
  }

  async onAuthStateChange(newUser) {
    const { user } = this.state;

    console.log('🔑 onAuthStateChanged: ', newUser);

    if (!newUser || !newUser.uid) {
      // If user was logged out, reset everything
      if (user.loggedIn || check.nonEmptyString(user.uid)) {
        console.log('🔑 Logging out...');
        await this.onLogout();
      }
      // If it's a login, upload state to DB!
    } else if (!user.loggedIn || !check.nonEmptyString(user.uid)) {
      console.log("🔑 It's a log in!: ", newUser);
      await this.onLogin(newUser.uid, newUser.email);
    }

    // Remove sign in progress indicator
    await this.setSession({
      isSigningIn: false,
    });
  }

  async onRoutesUpdate(newRoutes) {
    await this.setCacheState({ routes: newRoutes });
  }

  async onLocationsUpdate(newLocations) {
    await this.setCacheState({ locations: newLocations });
  }

  async onRidesUpdate(origin, destination, date, data) {
    const {
      cache: { rides = {}, expandedRidesData = {} },
    } = this.state;
    const path = `${origin}/${destination}/${date}`;

    // Merge expanded fields on new rides data if any
    let newData = data;
    if (check.nonEmptyArray(data)) {
      newData = data.map((r) => {
        if (check.nonEmptyObject(expandedRidesData[r.id])) {
          return Object.assign({}, r, expandedRidesData[r.id]);
        }
        return r;
      });
    }

    // Set rides
    const newRides = Object.assign({}, rides, {
      [path]: newData,
    });

    await this.setCacheState({ rides: newRides });
  }

  async onExpandedRideDataUpdate(origin, destination, date, rideId, data) {
    const {
      cache: { rides = {}, expandedRidesData = {} },
    } = this.state;
    const path = `${origin}/${destination}/${date}`;
    const ridesForPath = rides[path];

    // Merge expanded fields on new rides data if any
    let newRidesForPath = ridesForPath;
    if (check.nonEmptyArray(ridesForPath)) {
      newRidesForPath = ridesForPath.map((r) => {
        if (rideId === r.id) {
          return Object.assign({}, r, data || {});
        }
        return r;
      });
    }

    // Update rides
    const newRides = Object.assign({}, rides, {
      [path]: newRidesForPath,
    });

    // Update expanded rides data
    const newExpandedRidesData = Object.assign({}, expandedRidesData, {
      [rideId]: data || {},
    });

    await this.setCacheState({
      rides: newRides,
      expandedRidesData: newExpandedRidesData,
    });
  }

  async onUserDataUpdate(data) {
    // Merge user state properly
    const remote = data;
    const { user: local } = this.state;
    const merged = this.getMergedUserState(local, remote);

    // Update language used by app if it was set remotely
    if (merged.lang !== translationManager.getCurrentLanguage()) {
      translationManager.setLanguage(merged.lang);
    }

    await this.setUserState(merged);

    /*
    await this.setState({
      user: Object.assign(Busea.defaultUserState(), data || {}, {
        loggedIn,
        uid,
      }),
    });
    */
  }

  getMergedUserState(local, rawRemote) {
    const remote = Object.assign(Busea.defaultUserState(), rawRemote || {});
    const merged = Object.assign({}, remote);

    // Key-value fields
    const keyValue = ['uid', 'loggedIn', 'lang', 'email'];
    keyValue.forEach((key) => {
      if (!!local[key] && !remote[key]) {
        merged[key] = local[key];
      }
    });

    // Object mergeable fields
    const objectMergeable = [
      'cards',
      // 'ordersById',
      'otherEmails',
      'savedPassengers',
      'lastLocationsSearched',
      'lastRoutesSearched',
    ];
    objectMergeable.forEach(key => Object.assign(merged[key], local[key]));

    // Manage e-mails
    if (local.email && remote.email && local.email !== remote.email) {
      merged.otherEmails[sha1(local.email)] = local.email.trim().toLowerCase();
    }

    // For backward-compatibility purposes, remove any numeric keys from savedPassengers
    Object.keys(merged.savedPassengers).forEach((key) => {
      if (check.integer(parseInt(key, 10))) {
        delete merged.savedPassengers[key];
      }
    });

    return merged;
  }

  getRidesData(origin, destination, date) {
    const {
      cache: { rides = {} },
    } = this.state;
    const path = `${origin}/${destination}/${date}`;

    return rides[path] || [];
  }

  async setState(state, callback = () => {}) {
    return new Promise((resolve, reject) => {
      try {
        // Save state in memory
        super.setState(state, (...args) => {
          // Save state in localStorage
          Busea.saveState(this.state);

          // Return
          callback(...args);
          resolve(...args);
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  async setSession(newState) {
    const { session } = this.state;

    await this.setState({
      session: Object.assign({}, session, newState),
    });
  }

  async setUserState(newState) {
    const { user } = this.state;

    const newUser = Object.assign({}, user, newState);
    await this.setState({
      user: newUser,
    });

    // Save state in DB
    if (newUser.loggedIn) {
      await this.updateUserStateInDb(newUser);
    }
  }

  async setCacheState(newState) {
    const { cache } = this.state;

    await this.setState({
      cache: Object.assign({}, cache, newState),
    });
  }

  async updateUserStateInDb(newState) {
    if (!newState.loggedIn || !check.nonEmptyString(newState.uid)) {
      console.error(
        '[Busea] Trying to update firebase user state while not logged in. Aborting...',
      );
      return;
    }

    const filteredNewState = blacklist(newState, 'loggedIn', 'uid');

    await firebase
      .database()
      .ref(`/users/${newState.uid}/`)
      .update(filteredNewState);
  }

  render() {
    const {
      session: {
        hasSeenWelcomeScreen,
        selectedDeparture = null,
        selectedArrival = null,
        outDate = null,
        returnDate = null,
        outboundTrip = null,
        returnTrip = null,
      },
      user: { loggedIn, uid },
    } = this.state;

    const homePage = hasSeenWelcomeScreen ? '/book/' : '/welcome/';
    const pageProps = {
      appState: this.state,
      setSession: this.setSession,
      setUserState: this.setUserState,
    };

    const listeners = [
      <RoutesAndLocationsListener
        key="RoutesAndLocationsListener"
        onRoutesUpdate={this.onRoutesUpdate}
        onLocationsUpdate={this.onLocationsUpdate}
      />,
      <AuthListener
        key="AuthListener"
        online={navigator.onLine}
        onAuthStateChange={this.onAuthStateChange}
      />,
      <UserDataListener
        key="UserDataListener"
        online={navigator.onLine}
        userId={uid}
        loggedIn={loggedIn}
        onUserDataUpdate={this.onUserDataUpdate}
      />,
    ];

    if (selectedDeparture && selectedArrival) {
      if (outDate) {
        listeners.push(
          <RidesListener
            key="rides-listener-out"
            origin={selectedDeparture}
            destination={selectedArrival}
            date={outDate}
            onRidesUpdate={this.onRidesUpdate}
          />,
        );

        if (outboundTrip) {
          listeners.push(
            <ExpandedRideListener
              origin={selectedDeparture}
              destination={selectedArrival}
              date={outDate}
              rideId={outboundTrip.id}
              onExpandedRideDataUpdate={this.onExpandedRideDataUpdate}
            />,
          );
        }
      }
      if (returnDate) {
        listeners.push(
          <RidesListener
            key="rides-listener-return"
            origin={selectedArrival}
            destination={selectedDeparture}
            date={returnDate}
            onRidesUpdate={this.onRidesUpdate}
          />,
        );

        if (returnTrip) {
          listeners.push(
            <ExpandedRideListener
              origin={selectedArrival}
              destination={selectedDeparture}
              date={returnDate}
              rideId={returnTrip.id}
              onExpandedRideDataUpdate={this.onExpandedRideDataUpdate}
            />,
          );
        }
      }
    }

    return (
      <div
        style={{
          minWidth: '100vw',
          minHeight: '100vh',
        }}
      >
        {listeners}
        <Router>
          <LastLocationProvider>
            <Switch>
              <Route
                path="/welcome/"
                exact
                render={props => <WelcomePage {...props} {...pageProps} />}
              />
              <Route
                path="/login/"
                exact
                render={props => <LoginPage {...props} {...pageProps} />}
              />
              <Route
                path="/login/forgot-password/"
                exact
                render={props => <ForgotPasswordPage {...props} {...pageProps} />}
              />
              <Route
                path="/create-account/"
                exact
                render={props => <SignUpPage {...props} {...pageProps} />}
              />
              <Route
                path="/create-account/done/"
                exact
                render={props => <SignUpOkPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/"
                exact
                render={props => <BookingFormPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/details/departure-date"
                exact
                render={props => (
                  <DepartureDatePopup {...props} {...pageProps} getRidesData={this.getRidesData} />
                )}
              />
              <Route
                path="/book/details/return-date"
                exact
                render={props => (
                  <ReturnDatePopup {...props} {...pageProps} getRidesData={this.getRidesData} />
                )}
              />
              <Route
                path="/settings/passengers"
                exact
                render={props => (
                  <EditPassengersPage {...props} {...pageProps} getRidesData={this.getRidesData} />
                )}
              />
              <Route
                path="/settings/passengers/add"
                exact
                render={props => (
                  <AddPassengerPopup {...props} {...pageProps} getRidesData={this.getRidesData} />
                )}
              />
              <Route
                path="/book/out/"
                exact
                render={props => (
                  <OutTripsListPage {...props} {...pageProps} getRidesData={this.getRidesData} />
                )}
              />
              <Route
                path="/book/out/select/"
                exact
                render={props => <OutTripDetailsPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/out/select/seats/"
                exact
                render={props => <OutTripSeatSelectionPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/out/select/stops/"
                exact
                render={props => <OutTripStopsPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/return/"
                exact
                render={props => (
                  <ReturnTripsListPage {...props} {...pageProps} getRidesData={this.getRidesData} />
                )}
              />
              <Route
                path="/book/return/select/"
                exact
                render={props => <ReturnTripDetailsPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/return/select/seats/"
                exact
                render={props => <ReturnTripSeatSelectionPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/return/select/stops/"
                exact
                render={props => <ReturnTripStopsPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/passenger-details/"
                exact
                render={props => <PassengerDetailsPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/passenger-details/:passengerId/"
                exact
                render={props => <PassengerDetailsPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/checkout/"
                exact
                render={props => <CheckoutPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/checkout/pay/"
                exact
                render={props => <CreditCardPaymentPage {...props} {...pageProps} />}
              />
              <Route
                path="/book/booking-confirmed/"
                exact
                render={props => <BookingConfirmedPage {...props} {...pageProps} />}
              />
              <Route
                path="/orders/"
                exact
                render={props => <OrdersPage {...props} {...pageProps} />}
              />
              <Route
                path="/orders/:orderId/"
                exact
                render={props => <OrdersPage {...props} {...pageProps} />}
              />
              <Route
                path="/tickets/:ticketId/"
                exact
                render={props => <TicketDetailsPage {...props} {...pageProps} />}
              />
              <Route
                path="/tickets/:ticketId/stops/"
                exact
                render={props => <TicketStopsPage {...props} {...pageProps} />}
              />
              <Route
                path="/tickets/:ticketId/boarding-pass/:passengerIndex/"
                exact
                render={props => <TicketBoardingPassPage {...props} {...pageProps} />}
              />
              <Route
                path="/tickets/:ticketId/boarding-pass/"
                exact
                render={props => <TicketBoardingPassPage {...props} {...pageProps} />}
              />
              <Route
                path="/settings/"
                exact
                render={props => <SettingsPage {...props} {...pageProps} />}
              />
              <Route
                path="/settings/language/"
                exact
                render={props => <LanguageSettingsPage {...props} {...pageProps} />}
              />
              <Route path="/">
                <Redirect to={homePage} />
              </Route>
            </Switch>
          </LastLocationProvider>
        </Router>
      </div>
    );
  }
}
