import React from 'react';
import check from 'check-types';
import CheckoutPageUI from '../../ui/pages/CheckoutPageUI';

const CheckoutPage = (props) => {
  const {
    appState: {
      session: { outboundTrip, returnTrip, passengerDetails },
      user: { cards },
    },
    history,
  } = props;

  const payAmount = passengerDetails.length * (outboundTrip.price + (returnTrip ? returnTrip.price : 0));

  let card = null;
  if (check.nonEmptyObject(cards)) {
    [card] = Object.values(cards);
  }

  return (
    <CheckoutPageUI
      userSavedCard={card}
      payLink="/book/checkout/pay/"
      {...{
        passengerDetails,
        outboundTrip,
        returnTrip,
        payAmount,
        onBack: history.goBack,
      }}
    />
  );
};
export default CheckoutPage;
