import { Component } from 'react';
import firebase from 'firebase';
import filterProps from 'filter-properties';
import shallowObjectEquals from 'object-equal';

export default class UserDataListener extends Component {
  constructor(...args) {
    super(...args);
    this.currentUserPath = null;

    // Bindings
    this.onDataUpdate = this.onDataUpdate.bind(this);
  }

  componentDidMount() {
    this.startListening();
  }

  componentDidUpdate(prevProps) {
    const whitelist = ['loggedIn', 'userId', 'online'];

    if (
      !shallowObjectEquals(filterProps(whitelist, this.props), filterProps(whitelist, prevProps))
    ) {
      this.stopListening();
      this.startListening();
    }
  }

  componentWillUnmount() {
    this.stopListening();
  }

  onDataUpdate(snapshot) {
    const { loggedIn, onUserDataUpdate = () => {} } = this.props;

    if (!loggedIn) {
      return;
    }

    const val = snapshot.val();
    onUserDataUpdate(val || {});
  }

  startListening() {
    const { loggedIn, online, userId } = this.props;
    if (!loggedIn || !userId || !online) {
      return;
    }

    if (this.currentUserPath) {
      return;
    }

    this.currentUserPath = `/users/${userId}/`;

    firebase
      .database()
      .ref(this.currentUserPath)
      .on('value', this.onDataUpdate);
  }

  stopListening() {
    if (this.currentUserPath) {
      firebase
        .database()
        .ref(this.currentUserPath)
        .off('value', this.onDataUpdate);
      this.currentUserPath = null;
    }
  }

  render() {
    return null;
  }
}
