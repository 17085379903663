import React, { Fragment } from 'react';
import formatNumber from 'format-number';
import PaymentIcon from 'react-payment-icons';
import SelectedPassengers from './shared/SelectedPassengers';

import {
  DefaultScreen,
  Element,
  ElementGroup,
  Button,
  Bottom,
  Container,
  TicketDetails,
  Divider,
  LinkButton,
  HighligthedText,
  KeyValueElement,
} from '../theme';

import { translatorForNamespace } from '../../../lib/TranslationManager';

const t = translatorForNamespace('CheckoutPageUI');

const formatPrice = price => formatNumber({ prefix: '$', round: 2, padRight: 2 })(price);

const CheckoutPageUI = ({
  passengerDetails = [],
  outboundTrip,
  returnTrip,
  payAmount,
  payLink,
  onBack,
  userSavedCard,
}) => {
  let payLabel = t('Pay {amount}', { amount: formatPrice(payAmount) });

  if (userSavedCard) {
    payLabel = (
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PaymentIcon
          id={userSavedCard.brand.toLowerCase()}
          style={{ display: 'inline', height: '1rem', marginRight: 5 }}
        />
        {` ${payLabel} ${t('with card')} *${userSavedCard.last4}`}
      </div>
    );
  }

  /*
  const serviceFee = formatPrice(
    passengerDetails.length * (outboundTrip.fee + (returnTrip ? returnTrip.fee : 0)),
  );
  */

  const numberOfPassengers = passengerDetails.length;

  const outboundTicketTitle = (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <span>{`${returnTrip ? t('Forward trip') : t('One-way trip')}`}</span>
      <span>
        {(numberOfPassengers > 1 ? `${numberOfPassengers}x ` : '')
          + formatPrice(outboundTrip.grossPrice)}
      </span>
    </div>
  );

  const returnTicketTitle = returnTrip ? (
    <div
      style={{
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <span>{t('Return trip')}</span>
      <span>
        {(numberOfPassengers > 1 ? `${numberOfPassengers}x ` : '')
          + formatPrice(returnTrip.grossPrice)}
      </span>
    </div>
  ) : (
    ''
  );

  return (
    <DefaultScreen title={t('Ready?')} onBack={onBack}>
      <ElementGroup title={outboundTicketTitle}>
        <Element>
          <TicketDetails ticketDetails={outboundTrip} nPassengers={passengerDetails.length} />
        </Element>
      </ElementGroup>

      {returnTrip && (
        <ElementGroup title={returnTicketTitle}>
          <Element>
            <TicketDetails ticketDetails={returnTrip} nPassengers={passengerDetails.length} />
          </Element>
        </ElementGroup>
      )}

      <ElementGroup title={t('Price details')}>
        <KeyValueElement
          slim
          title={returnTrip ? t('Outbound ticket price') : t('Ticket price')}
          value={formatPrice(numberOfPassengers * outboundTrip.grossPrice)}
        />
        {returnTrip && (
          <KeyValueElement
            slim
            title={t('Return ticket price')}
            value={formatPrice(numberOfPassengers * returnTrip.grossPrice)}
          />
        )}

        <KeyValueElement
          slim
          title={t('Internet booking service')}
          value={formatPrice(
            numberOfPassengers * (outboundTrip.fee + (returnTrip ? returnTrip.fee : 0)),
          )}
        />
        <KeyValueElement
          title={<b>{t('TOTAL')}</b>}
          value={(
            <b>
              {formatPrice(
                numberOfPassengers * (outboundTrip.price + (returnTrip ? returnTrip.price : 0)),
              )}
            </b>
)}
        />
      </ElementGroup>

      {/*
        <ElementGroup title="Booking fee">
        <Element>
          <KeyValueElement
            title="Internet booking service"
            subtitle="Applied by the company to order tickets online"
            value={serviceFee}
          />
        </Element>
        </ElementGroup>
        */}

      <ElementGroup title={t('Passengers')}>
        <Element>
          <SelectedPassengers passengers={passengerDetails} />
        </Element>
      </ElementGroup>

      <ElementGroup>
        <Element>
          {t('Tickets are nominative.')}
          <br />
          {t("You'll need to present your")}
          {' '}
          <HighligthedText>{t('cedula or passport')}</HighligthedText>
          {' '}
          {t('along with your ticket before boarding.')}
        </Element>

        <Element>
          {t("You'll receive your tickets")}
          {' '}
          <HighligthedText>{`${t('by e-mail')}.`}</HighligthedText>
          <br />
          {t('You can present your tickets either on the Busea app or printed on paper.')}
        </Element>
      </ElementGroup>

      <Bottom>
        <Container style={{ textAlign: 'center' }}>
          <Button to={payLink} primary fluid>
            {payLabel}
          </Button>
          {userSavedCard && (
            <Fragment>
              <Divider text={t('or')} />
              <LinkButton to={`${payLink}#new-card`}>{t('Use another card')}</LinkButton>
            </Fragment>
          )}
        </Container>
      </Bottom>
    </DefaultScreen>
  );
};

export default CheckoutPageUI;
