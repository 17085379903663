import './fonts/open-sans/fonts.css';
import { primaryColor, textColor, discreetTextColor } from './Colors';

export const withDefaultFont = style => Object.assign(
  {
    fontFamily: "'Open Sans',Helvetica,Arial,sans-serif",
    fontWeight: 'normal',
    color: textColor,
    fontSize: '1rem',
    lineHeight: '1.4',
  },
  style,
);

export const withTopBarFont = style => Object.assign(
  withDefaultFont({
    fontWeight: 'bold',
    fontSize: '1.2rem',
    lineHeight: '1.2',
  }),
  style,
);

export const withWidgetTitleFont = style => Object.assign(
  withDefaultFont({
    fontSize: '1.1rem',
  }),
  style,
);

export const withBoldTitleFont = style => Object.assign(
  withDefaultFont({
    fontSize: '1.2rem',
    fontWeight: 'bold',
  }),
  style,
);

export const withWidgetSubtitleFont = style => Object.assign(
  withDefaultFont({
    fontSize: '0.8rem',
    lineHeight: '1.4',
    color: discreetTextColor,
    textTransform: 'none',
  }),
  style,
);

export const withPlaceholderFont = style => Object.assign(
  withDefaultFont({
    fontSize: '0.9rem',
    lineHeight: '1.4',
    color: discreetTextColor,
    textTransform: 'none',
  }),
  style,
);

export const withDividerFont = style => Object.assign(
  withDefaultFont({
    fontSize: '0.9rem',
    color: discreetTextColor,
    textTransform: 'uppercase',
  }),
  style,
);

export const withLinkButtonFont = style => Object.assign(
  withDefaultFont({
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '1.2',
    color: primaryColor,
    textTransform: 'uppercase',
  }),
  style,
);
