import HandleBars from 'handlebars';
import check from 'check-types';
import slugifyCore from 'slugify';
import uncamelize from 'uncamelize';
import detectBrowserLanguage from 'detect-browser-language';
import stringReplaceAll from 'string-replace-all';

const slugify = (txt) => {
  const slug = slugifyCore(txt, {
    replacement: '-',
    lower: true,
    remove: /(?![a-bA-B0-9-])/g,
  });

  if (slug.length > 25) {
    return slug.substr(0, 30);
  }

  return slug;
};

class TranslationManager {
  constructor() {
    this.initialized = false;
  }

  async init(
    appSlug,
    firebase,
    localTranslations = {},
    remoteTranslationsPath = null,
    supportedLanguages = ['en', 'fr', 'es'],
  ) {
    this.lang = null;
    this.appSlug = appSlug;
    this.firebase = firebase;
    this.localTranslations = localTranslations;
    this.remoteTranslations = null;
    this.remoteTranslationsPath = remoteTranslationsPath;
    this.supportedLanguages = supportedLanguages;

    // Open link to remote translations
    this.onRemoteTranslationsUpdate = this.onRemoteTranslationsUpdate.bind(this);
    if (this.remoteTranslationsPath) {
      this.firebase
        .database()
        .ref(remoteTranslationsPath)
        .on('value', this.onRemoteTranslationsUpdate);
    }

    this.initialized = true;
  }

  onRemoteTranslationsUpdate(snapshot) {
    this.remoteTranslations = snapshot.val() || {};
  }

  getCurrentLanguage() {
    if (!this.lang) {
      if (check.nonEmptyString(localStorage[`${this.appSlug}.language`])) {
        this.lang = localStorage[`${this.appSlug}.language`];
      } else {
        const browserLang = detectBrowserLanguage().split('-')[0];
        if (this.supportedLanguages.includes(browserLang.toLowerCase())) {
          this.lang = browserLang;
        } else {
          this.lang = null;
        }
      }

      console.log(`[TranslationManager] Saved language: ${this.lang}`);
    }

    return this.lang;
  }

  getTranslation(namespace, text, params = {}) {
    if (!this.initialized) {
      throw new Error(
        '[TranslationManager] Trying to get translation on non-initialized TranslationManager',
      );
    }

    const l = this.getCurrentLanguage() || 'en';
    const n = slugify(uncamelize(namespace));
    const t = slugify(text);

    let model = null;

    if (
      this.remoteTranslations
      && this.remoteTranslations[l]
      && this.remoteTranslations[l][n]
      && check.nonEmptyString(this.remoteTranslations[l][n][t])
    ) {
      model = this.remoteTranslations[l][n][t];
    } else if (
      this.localTranslations
      && this.localTranslations[l]
      && this.localTranslations[l][n]
      && check.nonEmptyString(this.localTranslations[l][n][t])
    ) {
      model = this.localTranslations[l][n][t];
    }

    // If we didn't find the translation, push the original text to Firebase
    // And set the text as being its own translation
    if (!model) {
      if (this.remoteTranslations && this.remoteTranslationsPath) {
        try {
          this.firebase
            .database()
            .ref(`${this.remoteTranslationsPath}/${l}/${n}/${t}`)
            .set(text);
        } catch (err) {
          console.error('[TranslationManager] Error pushing translation to DB: ', err);
        }
      }

      model = text;
    }

    // Compile the text with args
    const template = stringReplaceAll(stringReplaceAll(model, '{', '{{{'), '}', '}}}');
    return HandleBars.compile(template)(params || {});
  }

  setLanguage(lang) {
    const l = this.supportedLanguages.includes(lang.toLowerCase()) ? lang : null;

    this.lang = l;
    localStorage[`${this.appSlug}.language`] = l;

    console.log(`[TranslationManager] Setting language to: ${l}`);
    return l;
  }
}

const translationManager = new TranslationManager();

const translatorForNamespace = namespace => (text, params = {}) => translationManager.getTranslation(namespace, text, params);

export { translationManager, translatorForNamespace };
export default TranslationManager;
