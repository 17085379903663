import React from 'react';
import firebase from 'firebase';
import check from 'check-types';
import SettingsPageUI from '../../ui/pages/SettingsPageUI';

const SettingsPage = (props) => {
  const {
    appState: {
      user: {
        loggedIn = false, lang = null, cards, savedPassengers = {},
      },
    },
    setUserState = () => {},
    history,
  } = props;
  const version = process.env.REACT_APP_VERSION;

  const onLangChange = (newLang) => {
    if (newLang !== lang) {
      setUserState({ lang: newLang });
    }
  };

  const onLogOut = async () => {
    await firebase.auth().signOut();
    await setUserState({ loggedIn: false });
    history.push('/welcome/');
  };

  const onConnect = () => {
    history.push('/login/');
  };

  const onBack = () => {
    history.goBack();
  };

  let card = null;
  if (check.nonEmptyObject(cards)) {
    [card] = Object.values(cards);
  }

  const onDeleteCard = async () => {
    await setUserState({ cards: null });
  };

  const onEditPassengers = () => {
    history.push('/settings/passengers');
  };

  const supportedLanguages = { en: 'English', es: 'Español', fr: 'Français' };

  return (
    <SettingsPageUI
      loggedIn={loggedIn}
      selectedLanguage={supportedLanguages[lang] || 'English'}
      card={card}
      version={version}
      savedPassengers={Object.values(savedPassengers)}
      onEditPassengers={onEditPassengers}
      onConnect={onConnect}
      onLangChange={onLangChange}
      onLogOut={onLogOut}
      onBack={onBack}
      onDeleteCard={onDeleteCard}
    />
  );
};
export default SettingsPage;
