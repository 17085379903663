import React from 'react';
import { Header, Image } from 'semantic-ui-react';
import {
  Page, Container, Bottom, Button, LinkButton,
} from '../../theme';
import { withBoldTitleFont, withDefaultFont } from '../../theme/Fonts';
import buseaIcon from './icon.png';
import { translatorForNamespace } from '../../../../lib/TranslationManager';

const t = translatorForNamespace('WelcomePageUI');

const WelcomePageUI = ({ connectLink, registerLink, bookLink }) => (
  <Page>
    <div
      style={{
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Image src={buseaIcon} size="small" verticalAlign="middle" />
      <Header style={withBoldTitleFont({})}>{t('Welcome to Busea')}</Header>
      <div style={withDefaultFont({})}>{t("Let's redefine travel together")}</div>
    </div>
    <Bottom>
      <Container
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: '5%',
        }}
      >
        <Button to={bookLink} primary fluid style={{ marginRight: '2.5%' }}>
          {t('Book a trip')}
        </Button>
        <Button to={connectLink} fluid style={{ marginLeft: '2.5%' }}>
          {t('Log in')}
        </Button>
      </Container>

      <Container style={{ textAlign: 'center' }}>
        <LinkButton to={registerLink}>{t('Create an account')}</LinkButton>
      </Container>
    </Bottom>
  </Page>
);

export default WelcomePageUI;
